.aboutContainer {}

.headshot {
    
    width: 22em; 
    border-radius: 50%;
    border: 1px solid #1E293B;
    -webkit-box-shadow: 5px 5px 15px 5px #F2EBE2;
    box-shadow: 5px 5px 15px 5px #F2EBE2;
}

@media screen and (max-width: 400px) {
    .headshot {
        height: 10em;
        width: 11em;
    }
}