.heroSection {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 750px;
    background-image: url("/src/images/Ball-Gif.gif");
    background-attachment: fixed;
}

.heroTextDiv {
    padding-top: 300px;
    font-size: 42px;
    text-shadow: 1px 1px 2px gray;
}


@media screen and (max-width:400px) {
    .heroTextDiv {
        font-size: 30px;
        padding-top: 150px;
        padding-left: 1em;
        padding-right: 1em;
    }

    .heroSection {
        background-size: cover;
        background-position: center;
        height: 500px;
        background-image: url("/src/images/Ball-Gif-smol.gif");
        background-attachment: fixed;
    }
}