.nav {
    opacity: .8;
}

.navLogo {
    width: 70px;
    height: 70px;
    margin-top: 1rem;
    margin-left: 1rem;
}

.navItem {
    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: black;
    font-size: 16px;
    text-decoration: none;
    letter-spacing: .25em;

}

.navItem:hover {
    color: gray;
}

@media screen and (max-width:300px) {
    .navItem {
        padding-left: .2rem;
        padding-right: .2rem;
        font-size: 10px;
    }
}